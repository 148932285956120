import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, X, Globe } from 'lucide-react';

const Header = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);

    const navItems = [
        { path: '/programs', label: 'nav.programs' },
        { path: '/about', label: 'nav.about' },
        { path: '/community', label: 'nav.community' }
    ];

    const toggleLangMenu = () => {
        setIsLangMenuOpen(!isLangMenuOpen);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsLangMenuOpen(false);
    };

    return (
        <>
            <header className="fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-sm shadow-sm">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-between h-20">
                        <Link to="/" className="text-2xl font-bold text-primary">팜랜드</Link>

                        {/* 데스크톱 메뉴 */}
                        <nav className="hidden md:flex items-center space-x-8">
                            {navItems.map(item => (
                                <Link
                                    key={item.path}
                                    to={item.path}
                                    className="text-gray-600 hover:text-primary font-medium transition-colors"
                                >
                                    {t(item.label)}
                                </Link>
                            ))}
                            <div className="relative">
                                <button
                                    onClick={toggleLangMenu}
                                    className="flex items-center space-x-2 text-gray-600 hover:text-primary"
                                >
                                    <Globe className="w-5 h-5" />
                                    <span className="text-sm">{t(`language.${i18n.language}`)}</span>
                                </button>
                                {isLangMenuOpen && (
                                    <div className="absolute right-0 mt-2 w-32 bg-white rounded-lg shadow-lg py-2 z-50">
                                        <button
                                            onClick={() => changeLanguage('ko')}
                                            className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100 
                        ${i18n.language === 'ko' ? 'text-primary' : 'text-gray-600'}`}
                                        >
                                            {t('language.ko')}
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('en')}
                                            className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100
                        ${i18n.language === 'en' ? 'text-primary' : 'text-gray-600'}`}
                                        >
                                            {t('language.en')}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </nav>

                        {/* 모바일 메뉴 버튼 */}
                        <button
                            className="md:hidden p-2"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                        </button>
                    </div>
                </div>

                {/* 모바일 메뉴 */}
                {isOpen && (
                    <div className="md:hidden border-t border-gray-100">
                        <nav className="container mx-auto px-4 py-4">
                            {navItems.map(item => (
                                <Link
                                    key={item.path}
                                    to={item.path}
                                    className="block py-3 text-gray-600 hover:text-primary"
                                    onClick={() => setIsOpen(false)}
                                >
                                    {t(item.label)}
                                </Link>
                            ))}
                            <div className="py-3 border-t border-gray-100">
                                <button
                                    onClick={toggleLangMenu}
                                    className="flex items-center space-x-2 text-gray-600 w-full"
                                >
                                    <Globe className="w-5 h-5" />
                                    <span>{t(`language.${i18n.language}`)}</span>
                                </button>
                                {isLangMenuOpen && (
                                    <div className="mt-2 bg-gray-50 rounded-lg">
                                        <button
                                            onClick={() => changeLanguage('ko')}
                                            className={`w-full px-4 py-3 text-left hover:bg-gray-100 rounded-t-lg
                                                ${i18n.language === 'ko' ? 'text-primary font-medium' : 'text-gray-600'}`}
                                        >
                                            {t('language.ko')}
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('en')}
                                            className={`w-full px-4 py-3 text-left hover:bg-gray-100 rounded-b-lg
                                                ${i18n.language === 'en' ? 'text-primary font-medium' : 'text-gray-600'}`}
                                        >
                                            {t('language.en')}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </nav>
                    </div>
                )}
            </header>
            {/* 헤더 높이만큼 여백 추가 */}
            <div className="h-20"></div>
        </>
    );
};

export default Header;
