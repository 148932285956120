import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Tab } from '@headlessui/react';
import { MessageCircle, Image, Calendar, Star } from 'lucide-react';
import { communityData } from '../data/communityData';

const CommunityPage = () => {
    const { t } = useTranslation();

    const categories = {
        gallery: {
            name: t('community.gallery.title'),
            icon: <Image className="w-5 h-5" />,
            items: communityData.gallery
        },
        reviews: {
            name: t('community.reviews.title'),
            icon: <MessageCircle className="w-5 h-5" />,
            items: communityData.reviews
        },
        events: {
            name: t('community.events.title'),
            icon: <Calendar className="w-5 h-5" />,
            items: communityData.events
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('meta.community.title')} - 팜랜드</title>
                <meta name="description" content={t('meta.community.description')} />
            </Helmet>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-20">
                <motion.h1
                    className="text-4xl md:text-5xl font-bold text-center mb-12"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {t('community.title')}
                </motion.h1>

                <Tab.Group>
                    <Tab.List className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 rounded-xl bg-gray-100 p-2 mb-8">
                        {Object.entries(categories).map(([key, category]) => (
                            <Tab
                                key={key}
                                className={({ selected }) =>
                                    `w-full rounded-lg py-3 px-4 text-sm md:text-base font-medium transition-all duration-200
                                    ${selected
                                        ? 'bg-white text-primary shadow-md'
                                        : 'text-gray-600 hover:bg-white/50 hover:text-primary'
                                    }`
                                }
                            >
                                <div className="flex items-center justify-center space-x-2">
                                    {category.icon}
                                    <span>{category.name}</span>
                                </div>
                            </Tab>
                        ))}
                    </Tab.List>

                    <Tab.Panels className="mt-8">
                        {Object.entries(categories).map(([key, category]) => (
                            <Tab.Panel
                                key={key}
                                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                            >
                                {category.items.map((item) => (
                                    <motion.div
                                        key={item.id}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                                    >
                                        {item.image && (
                                            <img
                                                src={item.image}
                                                alt={item.title}
                                                className="w-full h-48 object-cover"
                                            />
                                        )}
                                        <div className="p-4">
                                            <h3 className="text-lg font-semibold">
                                                {item.title || `${item.program} - ${item.author}`}
                                            </h3>
                                            <p className="text-sm text-gray-500 mt-1">{item.date}</p>
                                            {item.content && (
                                                <p className="mt-2 text-gray-600">{item.content}</p>
                                            )}
                                            {item.description && (
                                                <p className="mt-2 text-gray-600">{item.description}</p>
                                            )}
                                            {item.rating && (
                                                <div className="flex items-center mt-2">
                                                    {[...Array(item.rating)].map((_, i) => (
                                                        <Star
                                                            fill="currentColor"
                                                            key={i}
                                                            className="w-4 h-4 text-yellow-400 fill-current"
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </motion.div>
                                ))}
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    );
};

export default CommunityPage;
