import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Calendar, Sun, Cloud, Snowflake, Leaf } from 'lucide-react';

const SeasonalPrograms = () => {
  const { t } = useTranslation();

  const programs = [
    {
      season: 'spring',
      icon: <Cloud className="w-8 h-8" />,
      activities: ['strawberry', 'planting'],
      color: 'from-pink-500 to-rose-500'
    },
    {
      season: 'summer',
      icon: <Sun className="w-8 h-8" />,
      activities: ['potato', 'waterplay'],
      color: 'from-yellow-400 to-orange-500'
    },
    {
      season: 'autumn',
      icon: <Leaf className="w-8 h-8" />,
      activities: ['apple', 'sweetpotato'],
      color: 'from-orange-500 to-red-500'
    },
    {
      season: 'winter',
      icon: <Snowflake className="w-8 h-8" />,
      activities: ['kimchi', 'ricecake'],
      color: 'from-blue-400 to-blue-600'
    }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12"
        >
          {t('seasonal.title')}
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {programs.map((program) => (
            <motion.div
              key={program.season}
              whileHover={{ y: -5 }}
              className={`bg-gradient-to-br ${program.color} p-6 rounded-xl text-white`}
            >
              <div className="flex items-center mb-4">
                {program.icon}
                <h3 className="text-xl font-semibold ml-3">
                  {t(`seasons.${program.season}`)}
                </h3>
              </div>
              <ul className="space-y-2">
                {program.activities.map((activity) => (
                  <li key={activity} className="flex items-center">
                    <Calendar className="w-4 h-4 mr-2" />
                    {t(`activities.${activity}`)}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SeasonalPrograms;
