import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            ko: {
                translation: {
                    welcome: '팜랜드에 오신 것을 환영합니다',
                    meta: {
                        home: {
                            title: '홈',
                            description: '팜랜드에서 제공하는 다양한 농촌 체험 프로그램을 경험해보세요.'
                        },
                        about: {
                            title: '소개',
                            description: '팜랜드는 자연과 함께하는 특별한 체험을 제공합니다.'
                        },
                        programs: {
                            title: '프로그램',
                            description: '계절별 특색있는 농촌 체험 프로그램을 만나보세요.'
                        },
                        community: {
                            title: '커뮤니티',
                            description: '팜랜드 방문객들의 생생한 후기와 이야기를 공유합니다.'
                        }
                    },
                    nav: {
                        home: '홈',
                        programs: '프로그램',
                        about: '소개',
                        community: '커뮤니티'
                    },
                    hero: {
                        title: '자연과 함께하는 특별한 체험',
                        description: '팜랜드에서 제공하는 다양한 농촌 체험 프로그램으로 특별한 추억을 만들어보세요.',
                        cta: '프로그램 둘러보기'
                    },
                    features: {
                        title: '팜랜드만의 특별함',
                        eco: {
                            title: '친환경 농장',
                            description: '자연과 조화를 이루는 친환경 농법으로 운영됩니다.'
                        },
                        family: {
                            title: '가족 친화적',
                            description: '온 가족이 함께 즐길 수 있는 다양한 프로그램'
                        },
                        seasonal: {
                            title: '계절별 프로그램',
                            description: '사계절 특색있는 체험 활동을 제공합니다.'
                        },
                        experience: {
                            title: '특별한 경험',
                            description: '도시에서는 경험할 수 없는 특별한 체험'
                        }
                    },
                    programs: {
                        title: '농촌 체험 프로그램',
                        strawberry: {
                            title: '딸기 수확 체험',
                            description: '달콤한 딸기를 직접 수확해보세요.'
                        },
                        potato: {
                            title: '감자 수확 체험',
                            description: '땅속의 보물 감자를 캐보세요.'
                        },
                        rice: {
                            title: '모내기 체험',
                            description: '우리나라 전통 농사를 체험해보세요.'
                        }
                    },
                    footer: {
                        description: '팜랜드는 도시와 농촌을 잇는 가교 역할을 합니다.',
                        links: '바로가기',
                        contact: '연락처',
                        social: 'SNS',
                        address: '경기도 양평군 서종면 북한강로 123',
                        phone: '031-123-4567',
                        email: 'info@farmland.kr',
                        rights: '모든 권리 보유',
                        programs: '프로그램',
                        about: '소개',
                        community: '커뮤니티'
                    },
                    usage: {
                        title: '이용 방법',
                        step1: {
                            title: '프로그램 선택',
                            description: '원하시는 체험 프로그램을 선택해주세요'
                        },
                        step2: {
                            title: '날짜 및 인원 선택',
                            description: '방문하실 날짜와 인원을 선택해주세요'
                        },
                        step3: {
                            title: '예약 및 결제',
                            description: '예약 정보를 확인하고 결제를 진행해주세요'
                        },
                        step4: {
                            title: '체험 참여',
                            description: '예약하신 날짜에 방문하여 체험을 즐겨주세요'
                        }
                    },
                    reservation: {
                        title: '예약하기',
                        date: '날짜 선택',
                        time: '시간 선택',
                        participants: '인원 선택',
                        selectDate: '날짜를 선택하세요',
                        selectTime: '시간을 선택하세요',
                        submit: '예약하기',
                        success: '예약이 완료되었습니다',
                        error: '예약 중 오류가 발생했습니다'
                    },
                    weather: {
                        sunny: {
                            title: '맑음',
                            description: '화창한 날씨입니다'
                        },
                        rainy: {
                            title: '비',
                            description: '실내 프로그램을 추천드립니다'
                        },
                        cloudy: {
                            title: '흐림',
                            description: '야외 활동하기 좋은 날씨입니다'
                        },
                        recommendation: '오늘 추천 프로그램'
                    },
                    activities: {
                        strawberry: '딸기 수확',
                        apple: '사과 따기',
                        potato: '감자 수확',
                        sweetpotato: '고구마 수확',
                        planting: '모종 심기',
                        waterplay: '물놀이',
                        kimchi: '김장 담그기',
                        ricecake: '떡메치기'
                    },
                    seasons: {
                        spring: '봄',
                        summer: '여름',
                        autumn: '가을',
                        winter: '겨울'
                    },
                    seasonal: {
                        title: '계절별 프로그램'
                    },
                    about: {
                        story: {
                            title: '팜랜드 이야기',
                            content1: '팜랜드는 2020년부터 도시와 농촌의 가교 역할을 하고 있습니다.',
                            content2: '우리는 자연과 함께하는 특별한 경험을 제공합니다.'
                        },
                        title: '팜랜드 소개',
                        location: {
                            title: '오시는 길',
                            address: '경기도 양평군 서종면 북한강로 123'
                        },
                        contact: {
                            title: '연락처',
                            phone: '031-123-4567'
                        },
                        hours: {
                            title: '운영시간',
                            time: '09:00 ~ 18:00 (연중무휴)'
                        }
                    },
                    community: {
                        title: '커뮤니티',
                        gallery: {
                            title: '갤러리',
                            description: '팜랜드의 다양한 활동 모습을 사진으로 만나보세요'
                        },
                        reviews: {
                            title: '후기',
                            description: '방문객들의 생생한 체험 후기를 확인해보세요'
                        },
                        events: {
                            title: '이벤트',
                            description: '특별한 행사와 이벤트 소식을 전해드립니다'
                        }
                    },
                    program: {
                        reserve: '예약하기'
                    },
                    language: {
                        ko: '한국어',
                        en: 'English'
                    }
                }
            },
            en: {
                translation: {
                    welcome: 'Welcome to Farm Land',
                    meta: {
                        home: {
                            title: 'Home',
                            description: 'Experience various rural activities provided by Farm Land.'
                        },
                        about: {
                            title: 'About',
                            description: 'Farm Land offers special experiences in harmony with nature.'
                        },
                        programs: {
                            title: 'Programs',
                            description: 'Discover seasonal rural experience programs.'
                        },
                        community: {
                            title: 'Community',
                            description: 'Share stories and reviews from Farm Land visitors.'
                        }
                    },
                    nav: {
                        home: 'Home',
                        programs: 'Programs',
                        about: 'About',
                        community: 'Community'
                    },
                    hero: {
                        title: 'Special Experience with Nature',
                        description: 'Create special memories with various rural experience programs provided by Farm Land.',
                        cta: 'Explore Programs'
                    },
                    features: {
                        title: 'Farm Land Special Features',
                        eco: {
                            title: 'Eco-friendly Farm',
                            description: 'Operated with eco-friendly farming methods in harmony with nature.'
                        },
                        family: {
                            title: 'Family-friendly',
                            description: 'Various programs for the whole family to enjoy'
                        },
                        seasonal: {
                            title: 'Seasonal Programs',
                            description: 'Providing unique seasonal experiences'
                        },
                        experience: {
                            title: 'Special Experience',
                            description: 'Unique experiences unavailable in the city'
                        }
                    },
                    programs: {
                        title: 'Rural Experience Programs',
                        strawberry: {
                            title: 'Strawberry Picking',
                            description: 'Pick sweet strawberries yourself.'
                        },
                        potato: {
                            title: 'Potato Harvesting',
                            description: 'Dig up treasure potatoes from the ground.'
                        },
                        rice: {
                            title: 'Rice Planting',
                            description: 'Experience traditional Korean farming.'
                        }
                    },
                    footer: {
                        description: 'Farm Land serves as a bridge between urban and rural areas.',
                        links: 'Quick Links',
                        contact: 'Contact',
                        social: 'Social Media',
                        address: '123 Bukhangang-ro, Seojong-myeon, Yangpyeong-gun, Gyeonggi-do',
                        phone: '031-123-4567',
                        email: 'info@farmland.kr',
                        rights: 'All Rights Reserved',
                        programs: 'Programs',
                        about: 'About',
                        community: 'Community'
                    },
                    usage: {
                        title: 'How to Use',
                        step1: {
                            title: 'Select Program',
                            description: 'Choose your desired experience program'
                        },
                        step2: {
                            title: 'Choose Date & Participants',
                            description: 'Select your preferred date and number of participants'
                        },
                        step3: {
                            title: 'Book & Pay',
                            description: 'Review your booking information and complete payment'
                        },
                        step4: {
                            title: 'Join Experience',
                            description: 'Visit on your scheduled date and enjoy the experience'
                        }
                    },
                    reservation: {
                        title: 'Make a Reservation',
                        date: 'Select Date',
                        time: 'Select Time',
                        participants: 'Number of Participants',
                        selectDate: 'Choose a date',
                        selectTime: 'Choose a time',
                        submit: 'Make Reservation',
                        success: 'Reservation completed',
                        error: 'An error occurred during reservation'
                    },
                    weather: {
                        sunny: {
                            title: 'Sunny',
                            description: 'Beautiful sunny weather'
                        },
                        rainy: {
                            title: 'Rainy',
                            description: 'Indoor programs recommended'
                        },
                        cloudy: {
                            title: 'Cloudy',
                            description: 'Perfect weather for outdoor activities'
                        },
                        recommendation: "Today's Recommended Programs"
                    },
                    activities: {
                        strawberry: 'Strawberry Picking',
                        apple: 'Apple Picking',
                        potato: 'Potato Harvesting',
                        sweetpotato: 'Sweet Potato Harvesting',
                        planting: 'Seedling Planting',
                        waterplay: 'Water Play',
                        kimchi: 'Kimchi Making',
                        ricecake: 'Rice Cake Making'
                    },
                    seasons: {
                        spring: 'Spring',
                        summer: 'Summer',
                        autumn: 'Autumn',
                        winter: 'Winter'
                    },
                    seasonal: {
                        title: 'Seasonal Programs'
                    },
                    about: {
                        story: {
                            title: 'Farm Land Story',
                            content1: 'Farm Land has been bridging urban and rural communities since 2020.',
                            content2: 'We provide special experiences in harmony with nature.'
                        },
                        title: 'About Farm Land',
                        location: {
                            title: 'Location',
                            address: '123 Bukhangang-ro, Seojong-myeon, Yangpyeong-gun, Gyeonggi-do'
                        },
                        contact: {
                            title: 'Contact',
                            phone: '031-123-4567'
                        },
                        hours: {
                            title: 'Operating Hours',
                            time: '09:00 ~ 18:00 (Open all year round)'
                        }
                    },
                    community: {
                        title: 'Community',
                        gallery: {
                            title: 'Gallery',
                            description: 'See photos of various activities at Farm Land'
                        },
                        reviews: {
                            title: 'Reviews',
                            description: 'Check out visitor reviews and experiences'
                        },
                        events: {
                            title: 'Events',
                            description: 'Find out about special events and activities'
                        }
                    },
                    program: {
                        reserve: 'Reserve Now'
                    },
                    language: {
                        ko: '한국어',
                        en: 'English'
                    }
                }
            }
        },
        lng: 'ko',
        fallbackLng: 'ko',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;