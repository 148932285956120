import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import Features from '../components/Features';
import SeasonalPrograms from '../components/SeasonalPrograms';
import WeatherWidget from '../components/WeatherWidget';
import WeatherBasedRecommendation from '../components/WeatherBasedRecommendation';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('meta.home.title')} - 팜랜드</title>
        <meta name="description" content={t('meta.home.description')} />
        <link rel="canonical" href="https://www.farmland.co.kr" />
      </Helmet>
      <Hero />
      <Features />
      <SeasonalPrograms />
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <WeatherWidget />
            <WeatherBasedRecommendation />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
