export const mockWeatherData = [
  {
    date: '01-01',
    main: 'Snow',
    description: '눈',
    temp: -2,
    humidity: 80,
    windSpeed: 3
  },
  {
    date: '04-15',
    main: 'Clear',
    description: '맑음',
    temp: 18,
    humidity: 65,
    windSpeed: 2
  },
  {
    date: '07-15',
    main: 'Clear',
    description: '맑음',
    temp: 28,
    humidity: 70,
    windSpeed: 1
  },
  {
    date: '10-15',
    main: 'Clouds',
    description: '흐림',
    temp: 15,
    humidity: 75,
    windSpeed: 2
  }
];
