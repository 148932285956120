import React from 'react';
import { Cloud, Sun, CloudRain, Snowflake, Wind, Droplets } from 'lucide-react';
import { motion } from 'framer-motion';
import { useRecoilValue } from 'recoil';
import { weatherState } from '../recoil/atoms/weatherAtom';
import { useWeather } from '../hooks/useWeather';
import { useTranslation } from 'react-i18next';

const WeatherWidget = () => {
  const { t } = useTranslation();
  const weather = useRecoilValue(weatherState);
  const { loading, error } = useWeather();

  const weatherIcons = {
    Clear: <Sun className="w-12 h-12 text-yellow-500" />,
    Clouds: <Cloud className="w-12 h-12 text-gray-500" />,
    Rain: <CloudRain className="w-12 h-12 text-blue-500" />,
    Snow: <Snowflake className="w-12 h-12 text-blue-400" />
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-white rounded-xl p-6 shadow-lg"
      >
        <div className="animate-pulse space-y-4">
          <div className="flex items-center space-x-4">
            <div className="rounded-full bg-gray-200 h-12 w-12"></div>
            <div className="space-y-2 flex-1">
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              <div className="h-3 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="h-2 bg-gray-200 rounded"></div>
            <div className="h-2 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          {weatherIcons[weather.main]}
          <div>
            <h3 className="text-3xl font-bold">{weather.temp}°C</h3>
            <p className="text-gray-600">{weather.description}</p>
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center space-x-2 text-gray-600">
          <Wind className="w-4 h-4" />
          <span>{weather.windSpeed}m/s</span>
        </div>
        <div className="flex items-center space-x-2 text-gray-600">
          <Droplets className="w-4 h-4" />
          <span>{weather.humidity}%</span>
        </div>
      </div>
    </motion.div>
  );
};

export default WeatherWidget;
