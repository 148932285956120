import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { MapPin, Phone, Mail, Clock } from 'lucide-react';
import ImageFallback from '../components/ImageFallback';

const AboutPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('meta.about.title')} - 팜랜드</title>
                <meta name="description" content={t('meta.about.description')} />
            </Helmet>

            <div className="relative h-[40vh] overflow-hidden">
                <div className="absolute inset-0">
                    <img
                        src="https://ai-img.guriout.com/image?img=넓은 농장 전경, 푸른 들판과 농작물, 드론 시점"
                        alt="농장 전경"
                        className="w-full h-full object-cover"
                        onError={(e) => {
                            e.target.style.display = 'none';
                            e.target.nextSibling.style.display = 'flex';
                        }}
                    />
                    <ImageFallback text="농장 전경 이미지" />
                </div>
                <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                    <motion.h1
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-4xl md:text-5xl font-bold text-white"
                    >
                        {t('about.title')}
                    </motion.h1>
                </div>
            </div>

            <div className="container mx-auto px-4 py-16">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="text-3xl font-bold mb-6 text-primary">
                            {t('about.story.title')}
                        </h2>
                        <p className="text-gray-600 mb-4">{t('about.story.content1')}</p>
                        <p className="text-gray-600">{t('about.story.content2')}</p>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="space-y-6"
                    >
                        <div className="flex items-start space-x-4">
                            <MapPin className="w-6 h-6 text-primary flex-shrink-0" />
                            <div>
                                <h3 className="font-semibold mb-1">{t('about.location.title')}</h3>
                                <p className="text-gray-600">{t('about.location.address')}</p>
                            </div>
                        </div>
                        <div className="flex items-start space-x-4">
                            <Phone className="w-6 h-6 text-primary flex-shrink-0" />
                            <div>
                                <h3 className="font-semibold mb-1">{t('about.contact.title')}</h3>
                                <p className="text-gray-600">{t('about.contact.phone')}</p>
                            </div>
                        </div>
                        <div className="flex items-start space-x-4">
                            <Clock className="w-6 h-6 text-primary flex-shrink-0" />
                            <div>
                                <h3 className="font-semibold mb-1">{t('about.hours.title')}</h3>
                                <p className="text-gray-600">{t('about.hours.time')}</p>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    );
};

export default AboutPage;
