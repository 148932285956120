import React from 'react';
import { Calendar, Users, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import ImageFallback from './ImageFallback';
import { useSetRecoilState } from 'recoil';
import { reservationModalState, reservationState } from '../recoil/atoms/reservationAtom';
import { useTranslation } from 'react-i18next';

const ProgramCard = ({ program }) => {
  const { t } = useTranslation();
  const setReservationModal = useSetRecoilState(reservationModalState);
  const setReservation = useSetRecoilState(reservationState);

  const handleReservation = () => {
    setReservation({
      programId: program.id,
      date: null,
      time: null,
      participants: 1,
      totalPrice: program.price,
    });
    setReservationModal(true);
  };

  return (
    <motion.div
      whileHover={{ y: -5 }}
      className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <div className="relative h-48">
        {program.image ? (
          <img
            src={program.image}
            alt={program.title}
            className="w-full h-full object-cover"
            onError={(e) => {
              e.target.style.display = 'none';
              e.target.nextSibling.style.display = 'flex';
            }}
          />
        ) : (
          <ImageFallback text="프로그램 이미지" />
        )}
      </div>
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-2">{program.title}</h3>
        <div className="space-y-2 text-gray-600">
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-2" />
            <span className="text-sm">{program.date}</span>
          </div>
          <div className="flex items-center">
            <Users className="w-4 h-4 mr-2" />
            <span className="text-sm">{program.capacity}명</span>
          </div>
          <div className="flex items-center">
            <Clock className="w-4 h-4 mr-2" />
            <span className="text-sm">{program.duration}</span>
          </div>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleReservation}
          className="mt-4 w-full bg-primary text-white py-2 rounded-lg hover:bg-primary/90 transition-colors"
        >
          {t('program.reserve')}
        </motion.button>
      </div>
    </motion.div>
  );
};

export default ProgramCard;
