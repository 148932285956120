export const programsData = [
  {
    id: 1,
    title: '딸기 수확 체험',
    description: '달콤한 딸기를 직접 수확해보세요.',
    image: 'https://ai-img.guriout.com/image?img=농장에서 빨간 딸기를 수확하는 모습, 밝은 분위기, 자연스러운 채광',
    season: 'spring',
    capacity: 20,
    duration: '2시간',
    price: 30000,
    date: '2024-03-01 ~ 2024-05-31'
  },
  {
    id: 2,
    title: '감자 수확 체험',
    description: '땅속의 보물 감자를 캐보세요.',
    image: 'https://ai-img.guriout.com/image?img=흙 속에서 감자를 캐내는 모습, 맑은 하늘, 푸른 들판',
    season: 'summer',
    capacity: 15,
    duration: '2시간',
    price: 25000,
    date: '2024-06-01 ~ 2024-08-31'
  },
  {
    id: 3,
    title: '사과 따기 체험',
    description: '가을의 달콤한 사과를 직접 수확해보세요.',
    image: 'https://ai-img.guriout.com/image?img=사과나무에서 빨간 사과를 따는 모습, 가을 단풍, 따뜻한 색감',
    season: 'fall',
    capacity: 25,
    duration: '3시간',
    price: 35000,
    date: '2024-09-01 ~ 2024-11-30'
  },
  {
    id: 4,
    title: '김장 체험',
    description: '우리나라 전통 김장 문화를 체험해보세요.',
    image: 'https://ai-img.guriout.com/image?img=전통적인 한국의 김장 문화, 배추와 양념, 따뜻한 분위기',
    season: 'winter',
    capacity: 30,
    duration: '4시간',
    price: 40000,
    date: '2024-12-01 ~ 2025-02-28'
  }
]; 