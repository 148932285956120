import { atom } from 'recoil';

export const weatherState = atom({
  key: 'weatherState',
  default: {
    main: 'Clear',
    description: 'clear sky',
    temp: 20,
    humidity: 60
  }
});
