import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Leaf, Users, Sun, Heart } from 'lucide-react';

const Features = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: <Leaf className="w-8 h-8" />,
      image: "https://ai-img.guriout.com/image?img=eco_farming",
      title: t('features.eco.title'),
      description: t('features.eco.description')
    },
    {
      icon: <Users className="w-8 h-8" />,
      image: "https://ai-img.guriout.com/image?img=family_activity",
      title: t('features.family.title'),
      description: t('features.family.description')
    },
    {
      icon: <Sun className="w-8 h-8" />,
      image: "https://ai-img.guriout.com/image?img=seasonal_program",
      title: t('features.seasonal.title'),
      description: t('features.seasonal.description')
    },
    {
      icon: <Heart className="w-8 h-8" />,
      image: "https://ai-img.guriout.com/image?img=special_experience",
      title: t('features.experience.title'),
      description: t('features.experience.description')
    }
  ];

  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-3xl font-bold text-center mb-12"
        >
          {t('features.title')}
        </motion.h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-xl overflow-hidden shadow-lg"
            >
              <img
                src={feature.image}
                alt={feature.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <div className="flex items-center mb-4">
                  {feature.icon}
                  <h3 className="text-xl font-semibold ml-3">{feature.title}</h3>
                </div>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
