import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import ProgramCard from '../components/ProgramCard';
import UsageSteps from '../components/UsageSteps';
import { programsData } from '../data/programsData';

const ProgramsPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('meta.programs.title')} - 팜랜드</title>
                <meta name="description" content={t('meta.programs.description')} />
            </Helmet>

            <div className="container mx-auto px-4 py-12">
                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-4xl font-bold text-center mb-12"
                >
                    {t('programs.title')}
                </motion.h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {programsData.map((program) => (
                        <ProgramCard key={program.id} program={program} />
                    ))}
                </div>
            </div>

            <UsageSteps />
        </>
    );
};

export default ProgramsPage;
