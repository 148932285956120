import { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { weatherState } from '../recoil/atoms/weatherAtom';
import { mockWeatherData } from '../data/mockWeatherData';

export const useWeather = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const setWeather = useSetRecoilState(weatherState);

  useEffect(() => {
    const getCurrentWeather = () => {
      try {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const currentDate = `${month}-${day}`;

        // 현재 날짜와 가장 가까운 날씨 데이터 찾기
        const weatherData = mockWeatherData.reduce((prev, curr) => {
          const prevDate = new Date(`2024-${prev.date}`);
          const currDate = new Date(`2024-${curr.date}`);
          const todayDate = new Date(`2024-${currentDate}`);

          const prevDiff = Math.abs(prevDate - todayDate);
          const currDiff = Math.abs(currDate - todayDate);

          return prevDiff < currDiff ? prev : curr;
        });

        setWeather(weatherData);
        setLoading(false);
      } catch (err) {
        setError('날씨 정보를 가져오는데 실패했습니다.');
        setLoading(false);
      }
    };

    // 1초 후에 데이터 로드 (로딩 상태 표시를 위해)
    setTimeout(getCurrentWeather, 1000);
  }, [setWeather]);

  return { loading, error };
};
