export const communityData = {
    gallery: [
        {
            id: 1,
            title: '봄 딸기 수확 현장',
            image: 'https://ai-img.guriout.com/image?img=행복한 가족들이 딸기를 수확하는 모습, 밝은 분위기',
            date: '2024-03-15'
        },
        {
            id: 2,
            title: '모종심기 체험',
            image: 'https://ai-img.guriout.com/image?img=아이들이 모종을 심는 모습, 화창한 날씨',
            date: '2024-03-20'
        },
        {
            id: 3,
            title: '감자 수확 체험',
            image: 'https://ai-img.guriout.com/image?img=감자를 수확하는 가족들, 즐거운 분위기',
            date: '2024-03-25'
        }
    ],
    reviews: [
        {
            id: 1,
            author: '김지민',
            program: '딸기 수확 체험',
            rating: 5,
            content: '아이들과 함께 특별한 추억을 만들었어요. 직원분들도 친절하고 시설도 깨끗했습니다.',
            date: '2024-03-10'
        },
        {
            id: 2,
            author: '이서준',
            program: '모종심기 체험',
            rating: 4,
            content: '자연과 함께하는 시간이 너무 좋았습니다. 다음에는 다른 프로그램도 체험해보고 싶어요.',
            date: '2024-03-18'
        },
        {
            id: 3,
            author: '박하은',
            program: '감자 수확 체험',
            rating: 5,
            content: '직접 감자를 캐보는 경험이 신기했어요. 신선한 감자로 저녁도 해먹었답니다!',
            date: '2024-03-22'
        }
    ],
    events: [
        {
            id: 1,
            title: '봄맞이 딸기 축제',
            description: '신선한 딸기로 만드는 특별한 요리 체험',
            date: '2024-04-01 ~ 2024-04-15',
            image: 'https://ai-img.guriout.com/image?img=딸기 축제 포스터, 밝은 색감'
        },
        {
            id: 2,
            title: '봄 모종심기 대회',
            description: '우리 가족만의 텃밭 가꾸기',
            date: '2024-04-20 ~ 2024-04-21',
            image: 'https://ai-img.guriout.com/image?img=모종심기 행사 포스터, 자연친화적 디자인'
        },
        {
            id: 3,
            title: '감자 수확 페스티벌',
            description: '감자 수확부터 요리까지 한번에!',
            date: '2024-05-01 ~ 2024-05-15',
            image: 'https://ai-img.guriout.com/image?img=감자 축제 포스터, 활기찬 분위기'
        }
    ]
}; 