import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram, Youtube } from 'lucide-react';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Farm Land</h3>
            <p className="text-sm">{t('footer.description')}</p>
          </div>
          
          <div>
            <h4 className="text-white text-lg font-semibold mb-4">{t('footer.links')}</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/programs" className="hover:text-primary transition-colors">
                  {t('footer.programs')}
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-primary transition-colors">
                  {t('footer.about')}
                </Link>
              </li>
              <li>
                <Link to="/community" className="hover:text-primary transition-colors">
                  {t('footer.community')}
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-white text-lg font-semibold mb-4">{t('footer.contact')}</h4>
            <ul className="space-y-2 text-sm">
              <li>{t('footer.address')}</li>
              <li>{t('footer.phone')}</li>
              <li>{t('footer.email')}</li>
            </ul>
          </div>

          <div>
            <h4 className="text-white text-lg font-semibold mb-4">{t('footer.social')}</h4>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-primary transition-colors">
                <Facebook />
              </a>
              <a href="#" className="hover:text-primary transition-colors">
                <Instagram />
              </a>
              <a href="#" className="hover:text-primary transition-colors">
                <Youtube />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-8 pt-8 text-sm text-center">
          <p>&copy; {new Date().getFullYear()} Farm Land. {t('footer.rights')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
