import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Calendar, Users, CreditCard, Check, Search } from 'lucide-react';

const UsageSteps = () => {
    const { t } = useTranslation();

    const steps = [
        {
            id: 1,
            title: t('usage.step1.title'),
            description: t('usage.step1.description'),
            icon: <Search className="w-6 h-6" />
        },
        {
            id: 2,
            title: t('usage.step2.title'),
            description: t('usage.step2.description'),
            icon: <Calendar className="w-6 h-6" />
        },
        {
            id: 3,
            title: t('usage.step3.title'),
            description: t('usage.step3.description'),
            icon: <CreditCard className="w-6 h-6" />
        },
        {
            id: 4,
            title: t('usage.step4.title'),
            description: t('usage.step4.description'),
            icon: <Users className="w-6 h-6" />
        }
    ];

    return (
        <section className="py-16 bg-white">
            <div className="container mx-auto px-4">
                <motion.h2
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-3xl font-bold text-center mb-12"
                >
                    {t('usage.title')}
                </motion.h2>

                <div className="relative">
                    <div className="absolute top-1/4 left-0 right-0 h-1 bg-primary/20 transform -translate-y-1/2" />
                    <div className="relative z-10 grid grid-cols-1 md:grid-cols-4 gap-8">
                        {steps.map((step, index) => (
                            <motion.div
                                key={step.id}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1 }}
                                className="flex flex-col items-center"
                            >
                                <div className="w-16 h-16 rounded-full bg-primary text-white flex items-center justify-center mb-4">
                                    {step.icon}
                                </div>
                                <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
                                <p className="text-sm text-gray-600 text-center">{step.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UsageSteps;
