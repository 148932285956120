import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Cloud, Sun, Umbrella, Loader } from 'lucide-react';
import { useRecoilValue } from 'recoil';
import { weatherState } from '../recoil/atoms/weatherAtom';
import { useWeather } from '../hooks/useWeather';

const WeatherBasedRecommendation = () => {
  const { t } = useTranslation();
  const weather = useRecoilValue(weatherState);
  const { loading, error } = useWeather();

  if (loading) {
    return (
      <div className="flex items-center justify-center p-6">
        <Loader className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-6">
        {error}
      </div>
    );
  }

  const getRecommendedPrograms = () => {
    const { main, description } = weather;
    
    if (main === 'Snow') {
      return {
        icon: <Umbrella className="w-8 h-8" />,
        title: t('weather.rainy.title'),
        description: t('weather.rainy.description'),
        programs: ['kimchi', 'ricecake'],
        color: 'from-blue-400 to-blue-600'
      };
    }
    
    if (main === 'Clear') {
      return {
        icon: <Sun className="w-8 h-8" />,
        title: t('weather.sunny.title'),
        description: t('weather.sunny.description'),
        programs: ['strawberry', 'apple', 'potato'],
        color: 'from-yellow-400 to-orange-500'
      };
    }
    
    return {
      icon: <Cloud className="w-8 h-8" />,
      title: t('weather.cloudy.title'),
      description: t('weather.cloudy.description'),
      programs: ['planting', 'sweetpotato'],
      color: 'from-gray-400 to-gray-600'
    };
  };

  const recommendation = getRecommendedPrograms();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-gradient-to-br ${recommendation.color} p-6 rounded-xl text-white`}
    >
      <div className="flex items-center mb-4">
        {recommendation.icon}
        <div className="ml-3">
          <h3 className="text-xl font-semibold">{recommendation.title}</h3>
          <p className="text-sm text-white/80">{weather.temp}°C</p>
        </div>
      </div>
      <p className="mb-4">{recommendation.description}</p>
      <h4 className="font-medium mb-2">{t('weather.recommendation')}</h4>
      <ul className="space-y-2">
        {recommendation.programs.map((program) => (
          <li key={program} className="flex items-center">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-white/20 px-3 py-2 rounded-lg w-full"
            >
              {t(`activities.${program}`)}
            </motion.div>
          </li>
        ))}
      </ul>
    </motion.div>
  );
};

export default WeatherBasedRecommendation;
