import { atom } from 'recoil';

export const reservationState = atom({
  key: 'reservationState',
  default: {
    programId: null,
    date: null,
    time: null,
    participants: 1,
    totalPrice: 0,
  },
});

export const reservationModalState = atom({
  key: 'reservationModalState',
  default: false,
});
