import React from 'react';
import { ImageOff } from 'lucide-react';

const ImageFallback = ({ text = '이미지를 불러올 수 없습니다' }) => {
  return (
    <div className="w-full h-full min-h-[200px] bg-gray-100 rounded-lg flex flex-col items-center justify-center text-gray-400">
      <ImageOff className="w-10 h-10 mb-2" />
      <p className="text-sm">{text}</p>
    </div>
  );
};

export default ImageFallback;
